import $ from 'jquery';
import { _confirm } from './dialogs';

export default socket => {
  $(document.body).on('click', '.js-confirmDisconnect', e => {
    e.preventDefault();
    _confirm(window.ndTranslations.confirmDisconnect, {
      yes: () => {
        if (socket) {
          socket.disconnect(true);
        }
        document.location.href = e.currentTarget.href;
      },
    });
  });
};
