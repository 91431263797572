import _ from './lodash';

export const isNightModeEnablable = (config = {}) => {
  const nightModeSupported = config.options && config.options.nightMode;
  return !!nightModeSupported;
};

export const isNightModeEnabled = (config = {}) => {
  const nightModeEnabled = isNightModeEnablable(config) && config.customOptions && config.customOptions.nightMode;
  return nightModeEnabled;
};

export const getStylesConfig = type =>
  ((window.THEME_CONFIG || {})['poll-styles'] || {})[type] || {};

export const getLogoConfig = () => {
  const opts = (window.THEME_CONFIG || {})['logo-participant'] || {};
  if (isNightModeEnabled(window.THEME_CONFIG)) {
    if (!opts['image-nightMode']) {
      return null;
    }
    return _.merge({}, opts, { image: opts['image-nightMode'] });
  }
  return opts;
};

export const fillPollStyleParams = (poll, session, noElection = false) => {
  if (!poll.style_params) {
    poll.style_params = {};
  }

  if (typeof poll.style_params === 'string') {
    try {
      poll.style_params = JSON.parse(poll.style_params);
    } catch (err) {
      poll.style_params = {};
    }
  }

  const styleConfig = getStylesConfig(poll.type);
  const pollStyleParams = poll.style_params || {};
  if (!styleConfig[poll.style]) {
    if (pollStyleParams.fallback) {
      poll.style = pollStyleParams.fallback;
    }
  }

  // Base defaults, depending on session status (auth?)
  const baseDefaultStyleParams = styleConfig._default || {};
  const publicBaseDefaultStyleParams = _.omit(baseDefaultStyleParams, ['$private', '$election']);
  const privateBaseDefaultStyleParams = baseDefaultStyleParams['$private'] || {};
  const electionBaseDefaultStyleParams = noElection ? {} : baseDefaultStyleParams['$election'] || {};
  const mergedDefaultStyleParams = session.assembly_votes
    ? { ...publicBaseDefaultStyleParams, ...privateBaseDefaultStyleParams, ...electionBaseDefaultStyleParams }
    : session.auth ? { ...publicBaseDefaultStyleParams, ...privateBaseDefaultStyleParams }
    : publicBaseDefaultStyleParams;

  const defaultStyleParams = styleConfig[poll.style] || {};
  const configuredStyleParams = _.pick(pollStyleParams, styleConfig._configurable);
  poll.style_params = _.merge({}, mergedDefaultStyleParams, defaultStyleParams, configuredStyleParams);

  return poll;
};

export const listStyles = type => {
  const styles = getStylesConfig(type);
  const keys = Object.keys(styles);
  const activeKeys = _.filter(keys, key => {
    if (key[0] === '_') { // ignored
      return false;
    }
    if (typeof styles[key] !== 'object') { // ignored
      return false;
    }
    const active = styles[key].active;
    if (active || typeof active === 'undefined') { // active? default = true
      return true;
    }
    return false;
  });
  return activeKeys;
};
