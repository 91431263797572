'use strict';

import $ from 'jquery';

// Use to trigger a DOM event, when $(...).trigger(...) does not work
// Example: trigger a click on an Alerty button
// This is because of "$.trigger" implementation (https://github.com/jquery/jquery/blob/master/src/event/trigger.js) which does not dispatch a "real" browser event
// dispatchEvent requires IE ≥ 9, so we use a cross-browser solution
// see https://gist.github.com/dciccale/6226151
export default (target, type) => {
  const el = typeof target === 'string'
    ? document.querySelector
      ? document.querySelector(target)
      : $(target).get(0)
    : target;

  if (!el || (!el.fireEvent && !el.dispatchEvent)) {
    throw new Error('Invalid target in "triggerEvent"');
  }

  if (document.createEvent) {
    const event = document.createEvent('HTMLEvents');
    event.initEvent(type, true, true);
    el.dispatchEvent(event);
  } else {
    const event = document.createEventObject();
    el.fireEvent('on' + type, event);
  }
};
