// Double requestAnimationFrame to wait reliably for DOM update
// See https://stackoverflow.com/questions/44145740/how-does-double-requestanimationframe-work
export const waitForDOMUpdate = fn =>
  requestAnimationFrame(() => requestAnimationFrame(fn));

export const TRANSITION_END = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';
export const ANIMATION_END = 'animationend webkitAnimationEnd oAnimationend MSAnimationEnd';

const waiter = eventName => ($element, fn) => {
  let triggered = false;
  const handler = e => {
    if (!triggered && $element.is(e.target)) {
      triggered = true;
      $element.off(eventName, handler);
      fn(e);
    }
  };
  $element.on(eventName, handler);
};

// Listen to "transitionend" event only once, handling bubbling and multiple transitions
export const waitForTransitionEnd = waiter(TRANSITION_END);
export const waitForAnimationEnd = waiter(ANIMATION_END);
