"use strict";
/* eslint-env browser */

import $ from 'jquery';
import alerty from 'alerty/dist/js/alerty';
import _ from './lodash';
import triggerEvent from './trigger-event';
import { waitForDOMUpdate } from './wait-dom';

//require('alerty/dist/css/alerty.css');

// Dialogs

/*
Note: dialogs are based on alerty, which does a pretty good job, nicely supports
stacking toasts but support for stacked dialogs (alert/prompt) is not implemented!
This is why we handle it here:
*/
const stack = []; // Array<[ type, message, options ]>, last = most recent

const fixStack = (type, message, options) => {
  // Does this dialog clear the whole stack first?
  if (options.clearStack) {
    stack.splice(0, stack.length);
  }
  stack.push([type, message, options]);
  // Remove precedent alerty nodes, we'll restore them AFTER this dialog is closed (if applicable)
  $('.alerty').remove();
  $('.alerty-overlay').remove();
  // Patch callbacks to handle stack restoration on close
  if (type === 'confirm') {
    handleStackInCallback(options, 'yes');
    handleStackInCallback(options, 'no');
  } else {
    handleStackInCallback(options, 'ok');
  }
  options._fixed_stack_ = true; // Avoid re-patching when restored stack
};

const handleStackInCallback = (options, key) => {
  if (options._fixed_stack_) {
    return;
  }
  const cb = options[key];
  options[key] = () => {
    if (cb) {
      setTimeout(cb, 0);
    }
    stack.pop(); // Remove myself from stack
    const data = stack.pop(); // Retrieve stacked dialog (note we pop, because next call will push to stack again)
    if (data) {
      // Restore the stacked dialog now I'm closed
      if (data[0] === 'confirm') {
        _confirm(data[1], data[2]);
      } else {
        _alert(data[1], data[2]);
      }
    }
  };
};

/*
Sometimes, an element in background keeps focus. If we press ENTER it will
click this element instead of the "OK" button, very confusing!
*/
function fixFocus() {
  document.activeElement.blur();
  // Focus to main dialog button
  var btn = document.querySelector('.btn-ok');
  if (btn) {
    btn.tabIndex = 10;
    btn.href = '#';
    $(btn).on('click', e => e.preventDefault());
    btn.focus();
  }
  btn = document.querySelector('.btn-cancel');
  if (btn) {
    btn.tabIndex = 11;
    btn.href = '#';
    $(btn).on('click', e => e.preventDefault());
  }
}

// Support expected keyboard shortcuts
$(document).on('keydown', '.alerty, .alerty-overlay', function (e) {
  if (e.keyCode === 9) { // TAB
    // Prevent changing focused link (user has to close dialog before)
    e.preventDefault();
  }
  // Other cases: let go (ENTER will confirm, thanks to focus)
  // Escape is not supported, we'd have to simulate complete process, better way would be to fork alerty at this point
});

export function _alert(message, options) {
  if (!options) {
    options = {};
  }
  if (!options.okLabel) {
    options.okLabel = (window.ndTranslations && window.ndTranslations.ALERT_OK) || 'OK';
  }
  if (!options.title) {
    options.title = (window.ndTranslations && window.ndTranslations.ALERT_TITLE) || 'Error';
  }
  if (options.reload && !options.ok) {
    options.ok = () => document.location.reload(true);
  }
  fixStack('alert', message, options);
  message = message.replace(/\n/g, '<br>');
  alerty.alert(message, options, options.ok);
  if (options.large) {
    waitForDOMUpdate(() => $('.alerty.alerty-show').css({ width: '600px', marginLeft: '-300px' }));
  }
  fixFocus();
}

export function _alertWithID(message, options, type = 'alert') {
  if(type === 'confirm') {
    _confirm(message, options);
  } else {
    _alert(message, options);
  }
  const alerty_id = $('.alerty').attr('id'),
        id = alerty_id.replace('alerty','');
  stack[stack.length - 1][2].id = id;
  return id;
}

export function closeAlert(id) {
  _.remove(stack, function(item) { // Remove alert from stack
    const item_options = item[2];
    return item_options && item_options.id && item_options.id === id;
  });
  const $alert = $('#alerty' + id),
        $overlay = $('#overlay-alerty' + id);
  if($alert.length) { // If alert is visible, hide it then show next in stack
    $alert.remove();
    $overlay.remove();
    if(stack.length > 0) {
      const next = stack.pop(); // Pop last cause next call will push to stack again
      if (next[0] === 'confirm') {
        _confirm(next[1], next[2]);
      } else {
        _alert(next[1], next[2]);
      }
    }
  }
};

export function _confirm(message, options = {}) {
  if (!options.okLabel) {
    options.okLabel = (window.ndTranslations && window.ndTranslations.CONFIRM_OK) || 'Confirmer';
  }
  if (!options.cancelLabel) {
    options.cancelLabel = (window.ndTranslations && window.ndTranslations.CONFIRM_CANCEL) || 'Annuler';
  }
  if (!options.title) {
    options.title = (window.ndTranslations && window.ndTranslations.CONFIRM_TITLE) || 'Question';
  }
  fixStack('confirm', message, options);
  message = message.replace(/\n/g, '<br>');
  alerty.confirm(message, options, options.yes, options.no);
  if (options.large) {
    waitForDOMUpdate(() => $('.alerty.alerty-show').css({ width: '600px', marginLeft: '-300px' }));
  }
  fixFocus();
}

const toastTemplate = _.template($('#toast-template').text());
const $toastContainer = $('#toasts');
const identifiedToasts = {};
export function _toast(message, { timeout = null, closable = true, success = false, error = false, id = null, onClose = null } = {}) {
  if (timeout === null) {
    // See "ideal toast duration"
    timeout = Math.max(2000, Math.min(7000, message.length * 50));
  }

  const identified = id && identifiedToasts[id];

  // Generate new DOM element
  const $toast = $(toastTemplate({
    message,
    closable,
    success,
    error,
  }));

  // Update DOM
  if (identified) {
    identified.$toast.replaceWith($toast); // TODO animate?
  } else {
    $toastContainer.append($toast); // TODO animate?
  }

  // Build external controller API
  let closed = false;
  let timeoutId = null;
  const controller = {
    close: () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      $toast.remove(); // TODO animate?
      closed = true;
      if (id) {
        delete identifiedToasts[id];
      }
      if (onClose) onClose();
    },
    closed: () => closed,
  };

  // Handle 'close' button
  $toast.on('click', '.ui-toast-close', e => {
    e.preventDefault();
    controller.close();
  });

  // Handle auto-close timeout
  if (identified) {
    clearTimeout(identified.timeoutId);
  }
  if (timeout) {
    timeoutId = setTimeout(controller.close, timeout);
  }

  // Store identified toasts
  if (id) {
    identifiedToasts[id] = { controller, $toast, timeoutId };
  }

  return controller;
}

// Global close allowed only for identified toasts
_toast.close = id => {
  if (identifiedToasts[id]) {
    identifiedToasts[id].controller.close();
  }
};

// Trigger action on latest dialog
export function _trigger(action) {
  triggerEvent('.alerty.alerty-show .btn-' + action, 'click');
}
