'use strict';

// Online/Offline
// Limit: participations
// Server health

import $ from 'jquery';
import { _alert, _confirm, _toast } from './dialogs';
import { checkRefDate } from './socket';

const defaultSocketStatusListener = () => {
  let wasOnline = false;
  let wasReconnecting = false;
  let displayDisconnectWarningTimeout = null;
  const $body = $(document.body);
  return online => {
    if (online) {
      if (wasReconnecting) {
        if (displayDisconnectWarningTimeout) {
          // Still in the grace period: just cancel the warning and don't display anything
          clearTimeout(displayDisconnectWarningTimeout);
          displayDisconnectWarningTimeout = null;
        } else {
          // The warning was displayed, replace it with a success notification
          _toast(window.ndTranslations.socketReconnected, { success: true, id: 'websocket-status' });
        }
      }
      wasReconnecting = false;
      wasOnline = true;
      $body.removeClass("offline").removeClass("was-online").addClass("online");
    } else {
      $body.addClass("offline").removeClass("online");
      if (wasOnline) {
        $body.addClass("was-online");
        wasReconnecting = true;
        if (displayDisconnectWarningTimeout) {
          // Avoid piling up timeouts
          clearTimeout(displayDisconnectWarningTimeout);
        }
        // Display a warning if disconnection lasts more than 1 second (grace period)
        displayDisconnectWarningTimeout = setTimeout(() => {
          if (!window.SKIP_DISCONNECTION_WARNING_TOAST) {
            _toast(window.ndTranslations.socketDisconnected, { timeout: 0, closable: true, error: true, id: 'websocket-status' });
          }
          displayDisconnectWarningTimeout = null;
        }, 1000);
      }
    }
  };
};

export const socketStatus = (socket, listener = defaultSocketStatusListener()) => {
  // Handle status change
  socket.on("connect", () => listener(true));
  socket.on("disconnect", () => listener(false));
  socket.on("reconnect_failed", () => listener(false));
  socket.on("error", () => listener(false));

  // Initial status
  $(() => listener(socket.connected));
};

const lowParticipationsWarningLimit = window.PARTICIPATIONS_LIMIT.warning;
const lowParticipationsErrorLimit = window.PARTICIPATIONS_LIMIT.error;
const lowParticipationsMessageLimit = window.PARTICIPATIONS_LIMIT.message;

let inParticipationsWarning = false;
let lastMaxParticipations = 0;
const defaultCumulLimitListener = () => ({ scope, total, max, unlimited, platformParticipations }) => {
  const remain = Math.max(0, max - total);
  let style = '';
  if (unlimited) {
    style = 'ok';
  } else if (scope === 'server') {
    // We're receiving server's participations quota, which means free server
    if (remain <= lowParticipationsErrorLimit) style = 'error';
    else if (remain <= lowParticipationsWarningLimit) style = 'warning';
    else style = 'ok';
    // Update gauge tank's height
    const height = !unlimited ? Math.round(100 * (max - total) / max) : 100;
    $('.js-quotaGauge').css({ height: height + '%' });
  } else {
    // We're receiving event's or vendor's quota, which means no free server
    if (remain <= lowParticipationsErrorLimit) style = 'error';
  }

  // Check for changes in subscription (max changed ?)
  if (lastMaxParticipations !== 0) {
    // max has increased, probably reloaded subscription
    if (max > lastMaxParticipations) {
      // Re-enable low participations warning
      window.DONT_REMIND_ABOUT_RUNNING_OUT_PARTICIPATIONS = false;
      lastMaxParticipations = max;
    }
  } else { // First time initialization
    lastMaxParticipations = max;
    if (remain > lowParticipationsMessageLimit) window.DONT_REMIND_ABOUT_RUNNING_OUT_PARTICIPATIONS = false;
  }

  $('.js-quotaCumulMax').text(unlimited ? '∞' : max);
  $('.js-quotaCumulCount').text(total);
  $('.js-quotaCumulRemain').text(unlimited ? '∞' : remain);
  $('.js-quotaCumul').removeClass('warning error ok').addClass(style);
  // Reaching participations quota, warn moderator
  if (unlimited ) {
    return;
  } else if (!unlimited && total >= (max - lowParticipationsMessageLimit) && !inParticipationsWarning) { // Reaching the limit
    if (total < max) { // Participations quota not reached yet
      if (!platformParticipations && !window.DONT_REMIND_ABOUT_RUNNING_OUT_PARTICIPATIONS) {
        inParticipationsWarning = true;
        _confirm(window.ndTranslations.quotaWarning.runningOut, {
            title: window.ndTranslations.quotaWarning.title,
            okLabel: window.ndTranslations.quotaWarning.ok,
            cancelLabel: window.ndTranslations.quotaWarning.cancel,
            yes: function () { inParticipationsWarning = false; },
            no: function () {
              window.DONT_REMIND_ABOUT_RUNNING_OUT_PARTICIPATIONS = true;
              inParticipationsWarning = false;
            }
        });
      }
    } else { // Participations quota reached, no more new participants allowed in session
      inParticipationsWarning = true;
      _alert(window.ndTranslations.quotaWarning.empty, {
        title: window.ndTranslations.quotaWarning.title,
        function () { inParticipationsWarning = false; }
      });
    }
  }
};

export const cumulLimit = (socket, listener = defaultCumulLimitListener()) => {
  socket.on('cumul-participations', listener);
};

const defaultServerHealthListener = () => ({ /*count, max,*/ load }) => {
  // TODO use a visual gauge library
  const color = load < 50 ? '#009900' : load < 80 ? '#999900' : '#990000';
  $('.server-health .value').css({ color }).text(load + '%');
};

export const serverHealth = (socket, listener = defaultServerHealthListener()) => {
  if (!socket._listeningServerHealth) {
    socket.emit('subscribe-server-health');
    socket._listeningServerHealth = true;
  }
  socket.on('server-health', listener);
};

const defaultSessionCounterListener = () => ({ count, max, total, procurations, isAuth = false  }) => {
  if (!window.EVENT.uses_cumul_limit && isAuth) {
    let style = '';
    if (count > window.EVENT.permanent_seats) {
      // above permanent seats: check max_audience_size
      if (count >= 0.75 * window.EVENT.max_audience_size) {
        style = 'warning'; // less than 25% → warning!
      } else if (count >= window.EVENT.max_audience_size) {
        style = 'error'; // reached threshold!
      }
    }
    if (max) {
      // Updated max audience size: can happen when changing session's mode
      window.MAX_AUDIENCE_SIZE = max;
    }
    $('.js-quotaOnlineMax').text(window.MAX_AUDIENCE_SIZE);
    $('.js-quotaOnlineRemain').text(window.MAX_AUDIENCE_SIZE - count);
    $('.js-quotaOnline').removeClass('warning error').addClass(style);
  }
  
  $('.js-usersConnected-text-with-nb').text(window.ndTranslations.usersConnected(count));
  $('.js-usersConnected').text(count);
  $('.js-usersUnique').text(total);
  // Only rendered on connection when step is 1 else presents will be smashed #2256
  $('.js-usersSessionPresents-label').text(window.ndTranslations.pollUsersPresent(count + procurations, procurations));

  if (isAuth) {
    const absents = total - count - procurations;
    const countAndProcurations = count + procurations;
    $('.js-usersPresent').text(count);
    $('.js-usersPresent-label').text(window.ndTranslations.participantsPresentsLabel(count));
    $('.js-usersPresentOrRepresented').text(countAndProcurations);
    $('.js-usersPresentOrRepresented-text').text(window.ndTranslations.participantsPresentsOrRepresented(countAndProcurations, procurations));
    $('.js-usersPresentOrRepresented-text-with-nb').text(window.ndTranslations.participantsPresentsOrRepresentedWithNb(countAndProcurations, procurations));
    $('.js-usersAbsent').text(absents);
    $('.js-usersAbsent-label').text(window.ndTranslations.participantsAbsentsLabel(absents));
    $('.js-usersProcurations').text(procurations);
    $('.js-usersProcurations-label').text(window.ndTranslations.participantsRepresentedLabel(procurations));
    $('.js-usersUnique-text').text(window.ndTranslations.participantsOrRegistered(total));
    $('.js-relaunchAbsentsPopup').prop("disabled", absents < 1 ? true : false);
  }
};

export const sessionCounter = (socket, listener = defaultSessionCounterListener()) => {
  socket.on('participants-counter:session', checkRefDate(({ count, max, total, procurations = 0, isAuth = false }) => listener({ count, max, total, procurations, isAuth })));
};

const defaultBroadcastorFlag = () => nbBroadcastor => {
  $('[data-broadcastor]').attr('data-broadcastor', nbBroadcastor > 0 ? '1' : '0');
};

export const broadcastorFlag = (socket, listener = defaultBroadcastorFlag()) => {
  socket.on('count-broadcastors', checkRefDate(nbBroadcastor => listener(nbBroadcastor)));
};

const $updateConfirmEventCount = {};
export const updateConfirmEventCount = (eventName, stats) => {
  let $div = $updateConfirmEventCount[eventName];
  if (!stats) {
    // Remove
    if ($div) {
      $div.remove();
      delete $updateConfirmEventCount[eventName];
    }
  } else {
    if (!$div) {
      $div = $(`<div data-ws="${eventName}" style="z-index:9999;position:fixed;right:25px;bottom:5px;color:#fff;text-shadow:1px 1px #000;font-size:10px;font-weight:bold;"></div>`);
      $div.appendTo(document.body);
      $updateConfirmEventCount[eventName] = $div;
    }
    if (typeof stats === 'string') {
      $div.text(stats);
    } else {
      $div.text(`${stats.received}/${stats.all}`);
    }
  }
};
