'use strict';

import io from 'socket.io-client';
import * as gauges from './gauges';
import Symbol from './symbol';
import { _alert, _toast } from './dialogs';
import $ from 'jquery';
import _ from './lodash';

// Store latest socket connection to be able to share it amongst different modules
// Use symbol if possible to make it inaccessible
const SOCKET_KEY = Symbol.for('CURRENT_SOCKET');
export const getSocket = () => window[SOCKET_KEY];
const setSocket = s => window[SOCKET_KEY] = s;

export function socketConnect(role, {
  sessionId = null,
  noRedirectOnError = false,
  ppt = false,
  broadcastCode = null,
  broadcastToken = null,
} = {}) {
  if (!sessionId && window.SESSION) {
    sessionId = window.SESSION.id;
  }
  if (role === "lobby" && !sessionId) {
    sessionId = 0;
  }
  var eventId = window.EVENT && window.EVENT.id;
  if (role === "global") {
    eventId = 0;
    sessionId = 0;
  }
  if (eventId !== 0 && !eventId) {
    alert('Invalid usage: no event id found');
  }

  // Connect websocket
  const opts = {
    path: window.URL_ROOT + "/socket.io",
    query: `event=${eventId}&session=${sessionId}&role=${role}&locale=${window.LOCALE}&ppt=${ppt ? '1' : ''}&bCode=${broadcastCode || ''}&bToken=${role === 'diffuseur' && broadcastToken || ''}`,
    forceNew: true,
    // Tweak reconnection settings
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
  };
  const socket = window.WSS ? io('https://' + window.location.hostname, opts) : io(opts);

  // Enable/disable ws message tracking, type '&' five times to (de)activate debug in moderator UI
  let wsEventConfirmEnabled = true;
  if (role === 'moderateur') {
    let counter = 0;
    $(document).on('keypress', e => {
      if (e.key === '&') {
        counter++;
      } else {
        counter = 0;
      }
      if (counter === 5) {
        wsEventConfirmEnabled = !wsEventConfirmEnabled;
        socket.emit('moderator:toggle-ws-event-confirm', wsEventConfirmEnabled);
        counter = 0;
      }
    });
  }
  socket.on('toggled-ws-event-confirm', (enabled, onConnect) => {
    if (role === 'moderateur' && !onConnect) {
      _toast(`Websocket key events tracking has been ${enabled ? 'enabled' : 'disabled'}`);
    }
    wsEventConfirmEnabled = enabled;
  });

  // Helper: send a message back to server when receiving this event
  socket.onConfirmed = (event, handler) => {
    socket.on(event, (...args) => {
      if (wsEventConfirmEnabled) {
        socket.emit('participant:confirm-event', event);
      }
      handler(...args);
    });
  };

  // Helper: emit an event that will be watched for reception confirmation
  const confirmCheckInterval = 1000;
  socket.emitConfirmed = (watchedEvent, emittedEvent, ...args) => {
    // watch until 100%
    const check = () => {
      socket.emit('moderator:pending-event-info', watchedEvent, false, (err, stats) => {
        // Error: show instead of counter
        if (err) {
          return gauges.updateConfirmEventCount(watchedEvent, err.message || String(err));
        }
        // Update counter
        gauges.updateConfirmEventCount(watchedEvent, stats);
        // Still missing: re-check later
        if (stats.missing > 0) {
          return setTimeout(check, confirmCheckInterval);
        }
        // Everyone received: clear counter after a small delay
        setTimeout(() => gauges.updateConfirmEventCount(watchedEvent, null), 1000);
      });
    };
    socket.emit(emittedEvent, ...args);
    if (wsEventConfirmEnabled) {
      // Trigger almost-immediate check
      setTimeout(check, 50);
    }
  };

  // Store socket for global access
  setSocket(socket);

  const onError = _.throttle(error => {
    error = (error && error.message) || String(error);
    const messages = window.ndTranslations.socketError || { redirectInfo: '' };
    let message = messages[error];
    if (message) {
      const redirectInfo = messages.redirectInfo;
      if (redirectInfo && !noRedirectOnError) {
        message += '\n\n' + redirectInfo;
        window.DISABLE_WD_WEBSOCKET = true;
      }
      _alert(message, {
        ok: () => {
          if (!noRedirectOnError) {
            if (role === 'global') { // e.g. moderator.direct
              document.location.reload(true);
            } else if (role === 'participant') {
              if (window.EVENT.personal) {
                document.location.href = window.URL_QUESTION_DIRECT;
              } else {
                document.location.href = window.URL_ROOT + "/" + window.EVENT.id;
              }
            } else { // staff
              document.location.href = window.URL_MODERATOR_DIRECT;
            }
          }
        }
      });
    } else {
      // Unknown error: on participant/broadcastor we don't want to show anything, just silently fail
      // otherwise any connection error will be visually reported, which happens often in real conditions
      // For other roles like moderator, error management is managed specifically
    }
  }, 100);

  // Specific error messages to user at connection
  socket.on('connect_error', onError);
  socket.on('reconnect_error', onError);
  socket.on('error', onError);

  // Refresh/redirect remotely
  let skipRedirectMessage = false;
  $(window).on('beforeunload', () => {
    skipRedirectMessage = true;
    // Restore value if unload finally doesn't occur
    setTimeout(() => {
      skipRedirectMessage = false;
    }, 1000);
  });

  socket.on("refresh-after-disconnect", (alertMessage = null) => {
    if (alertMessage && !skipRedirectMessage) {
      alert(alertMessage); // Using browser's alert to block redirection until user validated
    }
    document.location.reload(true);
  });

  socket.on("redirect", (url, alertMessage = null, cb = null) => {
    if (alertMessage && !skipRedirectMessage) {
      alert(alertMessage); // Using browser's alert to block redirection until user validated
    }
    setTimeout(() => document.location.href = url, 100);
    if (cb) cb();
  });

  // Try to reconnect from server disconnection, that may trigger an error so we can handle it properly
  socket.on("disconnect", reason => {
    if (reason === "io server disconnect") {
      setTimeout(function () {
        socket.connect();
      }, 500);
    }
  });

  // Log all events for debugging purpose
  socket.on("error", log("WebSocket error:"));
  socket.on("connect", log("connect"));
  socket.on("disconnect", log("disconnect"));
  socket.on("reconnect", log("reconnect"));
  socket.io.on("reconnect", log("socket.io.on.reconnect"));
  socket.on("reconnect_attempt", log("reconnect_attempt"));
  socket.on("reconnecting", log("reconnecting"));
  socket.on("reconnect_error", log("reconnect_error"));
  socket.on("reconnect_failed", log("reconnect_failed"));

  function log(prefix) {
    return () => {
      if (console && console.debug) { // eslint-disable-line no-console
        var args = Array.prototype.slice.call(arguments);
        args.unshift(prefix);
        console.debug.apply(console, args); // eslint-disable-line no-console
      }
    };
  }

  gauges.socketStatus(socket);

  return socket;
}

// Race-condition solver: when called with an object containing "refDate" parameter
export function checkRefDate(fn) {
  let lastRefDate = null;
  return function (o) {
    if (o.refDate && lastRefDate && lastRefDate > o.refDate) {
      // We receive an expired event, due to race conditions, ignore it
      return;
    }
    lastRefDate = o.refDate;
    return fn.apply(this, arguments);
  };
}
